<script>
export default {
  name: 'About'
}
</script>

<template>
  <div class="about">
    <div class="container">
      <h1>About Me</h1>
      <div>
        <p>
          For over a decade, I have been focused on solving interesting
          problems using programming.

          Solutions have come in many forms, such as
          creating/updating web applications, automating tasks at scale,
          architecting data pipelines, playing with data when creating
          useful visualizations, and more.

          However, the most rewarding
          experiences have come from making a positive difference
          for others, commonly by mentoring friends, family, &
          colleagues on their path in professional and/or personal development.
        </p>
        <p>
          To stay up-to-date with my professional work, check out the
          <router-link
            :to="{ name: 'resume' }"
          >
            resume
          </router-link> page.
        </p>
        <p>
          Back in college, I competed as a division-one springboard & platform
          diver. Enjoy a nice compilation of some past dives in the video below.
        </p>
      </div>
      <br>
      <div
        id="myCompilation"
        class="video-container"
      >
        <iframe
          src="https://www.youtube.com/embed/WS-CwvHR1rA"
          frameborder="0"
          allowfullscreen=""
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
